/* tslint:disable */
/* eslint-disable */
/**
 * Electricity Market Coupling Compass
 * API for Electricity Market Coupling Compass
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: yasir.latif@50hertz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AuctionType = {
    DayAhead: 'Day_ahead',
    IntraDay1: 'Intra_day_1',
    IntraDay2: 'Intra_day_2',
    IntraDay3: 'Intra_day_3'
} as const;

export type AuctionType = typeof AuctionType[keyof typeof AuctionType];


/**
 * 
 * @export
 * @interface BZBVariablesAverageResponse
 */
export interface BZBVariablesAverageResponse {
    /**
     * 
     * @type {string}
     * @memberof BZBVariablesAverageResponse
     */
    'bz_from': string;
    /**
     * 
     * @type {string}
     * @memberof BZBVariablesAverageResponse
     */
    'bz_to': string;
    /**
     * 
     * @type {string}
     * @memberof BZBVariablesAverageResponse
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof BZBVariablesAverageResponse
     */
    'end_time': string;
    /**
     * 
     * @type {{ [key: string]: ValuesValue; }}
     * @memberof BZBVariablesAverageResponse
     */
    'values': { [key: string]: ValuesValue; };
}
/**
 * 
 * @export
 * @interface BZBVariablesCombinedResponse
 */
export interface BZBVariablesCombinedResponse {
    /**
     * 
     * @type {BZBVariablesAverageResponse}
     * @memberof BZBVariablesCombinedResponse
     */
    'single_direction': BZBVariablesAverageResponse;
    /**
     * 
     * @type {BZBVariablesAverageResponse}
     * @memberof BZBVariablesCombinedResponse
     */
    'counter_direction': BZBVariablesAverageResponse;
    /**
     * 
     * @type {BZBVariablesAverageResponse}
     * @memberof BZBVariablesCombinedResponse
     */
    'combined': BZBVariablesAverageResponse;
}
/**
 * 
 * @export
 * @interface BZVariablesAverageResponse
 */
export interface BZVariablesAverageResponse {
    /**
     * 
     * @type {string}
     * @memberof BZVariablesAverageResponse
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof BZVariablesAverageResponse
     */
    'end_time': string;
    /**
     * 
     * @type {{ [key: string]: ValuesValue; }}
     * @memberof BZVariablesAverageResponse
     */
    'values': { [key: string]: ValuesValue; };
}
/**
 * 
 * @export
 * @interface BiddingZoneBordersCreate
 */
export interface BiddingZoneBordersCreate {
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'bz_name_from': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'bz_name_to': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'eic_from': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'eic_to': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'bzb_midpoint': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'from_to_angle': string;
    /**
     * 
     * @type {boolean}
     * @memberof BiddingZoneBordersCreate
     */
    'physical_border_exists': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'line_string': string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersCreate
     */
    'bzb': string;
}
/**
 * 
 * @export
 * @interface BiddingZoneBordersResponse
 */
export interface BiddingZoneBordersResponse {
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'bz_name_from': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'bz_name_to': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'eic_from': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'eic_to': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'bzb_midpoint': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'from_to_angle': string;
    /**
     * 
     * @type {boolean}
     * @memberof BiddingZoneBordersResponse
     */
    'physical_border_exists': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'line_string': string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersResponse
     */
    'bzb': string;
}
/**
 * 
 * @export
 * @interface BiddingZoneBordersUpdate
 */
export interface BiddingZoneBordersUpdate {
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'bz_name_from': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'bz_name_to': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'eic_from': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'eic_to': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'bzb_midpoint': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'from_to_angle': string;
    /**
     * 
     * @type {boolean}
     * @memberof BiddingZoneBordersUpdate
     */
    'physical_border_exists': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'line_string': string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneBordersUpdate
     */
    'bzb': string;
}
/**
 * 
 * @export
 * @interface BiddingZoneMappingCreate
 */
export interface BiddingZoneMappingCreate {
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingCreate
     */
    'eic': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingCreate
     */
    'pretty_name_long': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingCreate
     */
    'pretty_name_short': string;
    /**
     * 
     * @type {boolean}
     * @memberof BiddingZoneMappingCreate
     */
    'is_virtual': boolean;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingCreate
     */
    'non_virtual_parent_eic': string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingCreate
     */
    'member_state_account': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingCreate
     */
    'centroid': string;
}
/**
 * 
 * @export
 * @interface BiddingZoneMappingResponse
 */
export interface BiddingZoneMappingResponse {
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'eic': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'pretty_name_long': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'pretty_name_short': string;
    /**
     * 
     * @type {boolean}
     * @memberof BiddingZoneMappingResponse
     */
    'is_virtual': boolean;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'non_virtual_parent_eic': string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'member_state_account': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'centroid': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneMappingResponse
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface BiddingZoneNetPositionResponse
 */
export interface BiddingZoneNetPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneNetPositionResponse
     */
    'datetime': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneNetPositionResponse
     */
    'interval'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneNetPositionResponse
     */
    'eic': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZoneNetPositionResponse
     */
    'pretty_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BiddingZoneNetPositionResponse
     */
    'net_position'?: number | null;
}
/**
 * 
 * @export
 * @interface BiddingZonePriceResponse
 */
export interface BiddingZonePriceResponse {
    /**
     * 
     * @type {string}
     * @memberof BiddingZonePriceResponse
     */
    'datetime': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZonePriceResponse
     */
    'interval'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BiddingZonePriceResponse
     */
    'eic': string;
    /**
     * 
     * @type {string}
     * @memberof BiddingZonePriceResponse
     */
    'pretty_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BiddingZonePriceResponse
     */
    'price'?: number | null;
}
/**
 * 
 * @export
 * @interface BzbPriceSpreadResponse
 */
export interface BzbPriceSpreadResponse {
    /**
     * 
     * @type {string}
     * @memberof BzbPriceSpreadResponse
     */
    'datetime': string;
    /**
     * 
     * @type {string}
     * @memberof BzbPriceSpreadResponse
     */
    'interval': string;
    /**
     * 
     * @type {string}
     * @memberof BzbPriceSpreadResponse
     */
    'eic_from': string;
    /**
     * 
     * @type {string}
     * @memberof BzbPriceSpreadResponse
     */
    'eic_to': string;
    /**
     * 
     * @type {number}
     * @memberof BzbPriceSpreadResponse
     */
    'price_spread'?: number | null;
}
/**
 * 
 * @export
 * @interface DataAvailabilityResponse
 */
export interface DataAvailabilityResponse {
    /**
     * 
     * @type {number}
     * @memberof DataAvailabilityResponse
     */
    'availability_percentage': number;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ScheduledCommercialExchangeResponse
 */
export interface ScheduledCommercialExchangeResponse {
    /**
     * 
     * @type {string}
     * @memberof ScheduledCommercialExchangeResponse
     */
    'datetime': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCommercialExchangeResponse
     */
    'interval': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCommercialExchangeResponse
     */
    'eic_from': string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledCommercialExchangeResponse
     */
    'eic_to': string;
    /**
     * 
     * @type {number}
     * @memberof ScheduledCommercialExchangeResponse
     */
    'megawatt'?: number | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface ValuesValue
 */
export interface ValuesValue {
}

/**
 * BidingZoneBorderVariablesApi - axios parameter creator
 * @export
 */
export const BidingZoneBorderVariablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Average price spread for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
         * @summary Average Price Spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone1 
         * @param {string} biddingZone2 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        averagePriceSpreadBzbVariablesPriceSpreadAverageGet: async (startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('averagePriceSpreadBzbVariablesPriceSpreadAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('averagePriceSpreadBzbVariablesPriceSpreadAverageGet', 'endTime', endTime)
            // verify required parameter 'biddingZone1' is not null or undefined
            assertParamExists('averagePriceSpreadBzbVariablesPriceSpreadAverageGet', 'biddingZone1', biddingZone1)
            // verify required parameter 'biddingZone2' is not null or undefined
            assertParamExists('averagePriceSpreadBzbVariablesPriceSpreadAverageGet', 'biddingZone2', biddingZone2)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('averagePriceSpreadBzbVariablesPriceSpreadAverageGet', 'interval', interval)
            const localVarPath = `/bzb_variables/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone1 !== undefined) {
                localVarQueryParameter['bidding_zone_1'] = biddingZone1;
            }

            if (biddingZone2 !== undefined) {
                localVarQueryParameter['bidding_zone_2'] = biddingZone2;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Combined price for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
         * @summary Bzb Combined Scheduled Commercial Exchange
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone1 
         * @param {string} biddingZone2 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet: async (startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet', 'endTime', endTime)
            // verify required parameter 'biddingZone1' is not null or undefined
            assertParamExists('bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet', 'biddingZone1', biddingZone1)
            // verify required parameter 'biddingZone2' is not null or undefined
            assertParamExists('bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet', 'biddingZone2', biddingZone2)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet', 'interval', interval)
            const localVarPath = `/bzb_variables/scheduled_commercial_exchange/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone1 !== undefined) {
                localVarQueryParameter['bidding_zone_1'] = biddingZone1;
            }

            if (biddingZone2 !== undefined) {
                localVarQueryParameter['bidding_zone_2'] = biddingZone2;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidingZoneBorderVariablesApi - functional programming interface
 * @export
 */
export const BidingZoneBorderVariablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BidingZoneBorderVariablesApiAxiosParamCreator(configuration)
    return {
        /**
         * Average price spread for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
         * @summary Average Price Spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone1 
         * @param {string} biddingZone2 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BZBVariablesAverageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime, endTime, biddingZone1, biddingZone2, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBorderVariablesApi.averagePriceSpreadBzbVariablesPriceSpreadAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Combined price for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
         * @summary Bzb Combined Scheduled Commercial Exchange
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone1 
         * @param {string} biddingZone2 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BZBVariablesCombinedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime, endTime, biddingZone1, biddingZone2, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBorderVariablesApi.bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BidingZoneBorderVariablesApi - factory interface
 * @export
 */
export const BidingZoneBorderVariablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BidingZoneBorderVariablesApiFp(configuration)
    return {
        /**
         * Average price spread for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
         * @summary Average Price Spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone1 
         * @param {string} biddingZone2 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BZBVariablesAverageResponse>> {
            return localVarFp.averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime, endTime, biddingZone1, biddingZone2, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Combined price for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
         * @summary Bzb Combined Scheduled Commercial Exchange
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone1 
         * @param {string} biddingZone2 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<BZBVariablesCombinedResponse> {
            return localVarFp.bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime, endTime, biddingZone1, biddingZone2, interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BidingZoneBorderVariablesApi - interface
 * @export
 * @interface BidingZoneBorderVariablesApi
 */
export interface BidingZoneBorderVariablesApiInterface {
    /**
     * Average price spread for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
     * @summary Average Price Spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone1 
     * @param {string} biddingZone2 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBorderVariablesApiInterface
     */
    averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BZBVariablesAverageResponse>>;

    /**
     * Combined price for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
     * @summary Bzb Combined Scheduled Commercial Exchange
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone1 
     * @param {string} biddingZone2 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBorderVariablesApiInterface
     */
    bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<BZBVariablesCombinedResponse>;

}

/**
 * BidingZoneBorderVariablesApi - object-oriented interface
 * @export
 * @class BidingZoneBorderVariablesApi
 * @extends {BaseAPI}
 */
export class BidingZoneBorderVariablesApi extends BaseAPI implements BidingZoneBorderVariablesApiInterface {
    /**
     * Average price spread for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
     * @summary Average Price Spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone1 
     * @param {string} biddingZone2 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBorderVariablesApi
     */
    public averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig) {
        return BidingZoneBorderVariablesApiFp(this.configuration).averagePriceSpreadBzbVariablesPriceSpreadAverageGet(startTime, endTime, biddingZone1, biddingZone2, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Combined price for a given time period and bidding zone      start_time: Start time of the time period     end_time: End time of the time period (must be after start time)     bidding_zone_1: Bidding zone that shares the border     bidding_zone_2: Bidding zone that shares the border     interval: Time interval for the average price spread
     * @summary Bzb Combined Scheduled Commercial Exchange
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone1 
     * @param {string} biddingZone2 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBorderVariablesApi
     */
    public bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime: string, endTime: string, biddingZone1: string, biddingZone2: string, interval: string, options?: RawAxiosRequestConfig) {
        return BidingZoneBorderVariablesApiFp(this.configuration).bzbCombinedScheduledCommercialExchangeBzbVariablesScheduledCommercialExchangeAverageGet(startTime, endTime, biddingZone1, biddingZone2, interval, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BidingZoneBordersApi - axios parameter creator
 * @export
 */
export const BidingZoneBordersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new bidding zone border      body: Bidding zone borders create data
         * @summary Create Bzb
         * @param {BiddingZoneBordersCreate} biddingZoneBordersCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBzbBzBordersBiddingZoneBordersPost: async (biddingZoneBordersCreate: BiddingZoneBordersCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZoneBordersCreate' is not null or undefined
            assertParamExists('createBzbBzBordersBiddingZoneBordersPost', 'biddingZoneBordersCreate', biddingZoneBordersCreate)
            const localVarPath = `/bz_borders/bidding_zone_borders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biddingZoneBordersCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a bidding zone border      bzb_uuid: UUID of the bidding zone border to delete
         * @summary Delete Bzb
         * @param {string} bzbUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete: async (bzbUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bzbUuid' is not null or undefined
            assertParamExists('deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete', 'bzbUuid', bzbUuid)
            const localVarPath = `/bz_borders/bidding_zone_borders/{bzb_uuid}`
                .replace(`{${"bzb_uuid"}}`, encodeURIComponent(String(bzbUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all bidding zone borders
         * @summary Get All Bzb
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBzbBzBordersBiddingZoneBordersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bz_borders/bidding_zone_borders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a bidding zone border by UUID      bzb_uuid: UUID of the bidding zone border to get
         * @summary Get Bzb
         * @param {string} bzbUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBzbBzBordersBiddingZoneBordersBzbUuidGet: async (bzbUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bzbUuid' is not null or undefined
            assertParamExists('getBzbBzBordersBiddingZoneBordersBzbUuidGet', 'bzbUuid', bzbUuid)
            const localVarPath = `/bz_borders/bidding_zone_borders/{bzb_uuid}`
                .replace(`{${"bzb_uuid"}}`, encodeURIComponent(String(bzbUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing bidding zone border      bbzb_uuid: UUID of the bidding zone border to update      body: Bidding zone borders update data
         * @summary Update Bzb
         * @param {string} bzbUuid 
         * @param {BiddingZoneBordersUpdate} biddingZoneBordersUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBzbBzBordersBiddingZoneBordersBzbUuidPut: async (bzbUuid: string, biddingZoneBordersUpdate: BiddingZoneBordersUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bzbUuid' is not null or undefined
            assertParamExists('updateBzbBzBordersBiddingZoneBordersBzbUuidPut', 'bzbUuid', bzbUuid)
            // verify required parameter 'biddingZoneBordersUpdate' is not null or undefined
            assertParamExists('updateBzbBzBordersBiddingZoneBordersBzbUuidPut', 'biddingZoneBordersUpdate', biddingZoneBordersUpdate)
            const localVarPath = `/bz_borders/bidding_zone_borders/{bzb_uuid}`
                .replace(`{${"bzb_uuid"}}`, encodeURIComponent(String(bzbUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biddingZoneBordersUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidingZoneBordersApi - functional programming interface
 * @export
 */
export const BidingZoneBordersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BidingZoneBordersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new bidding zone border      body: Bidding zone borders create data
         * @summary Create Bzb
         * @param {BiddingZoneBordersCreate} biddingZoneBordersCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate: BiddingZoneBordersCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneBordersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBordersApi.createBzbBzBordersBiddingZoneBordersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a bidding zone border      bzb_uuid: UUID of the bidding zone border to delete
         * @summary Delete Bzb
         * @param {string} bzbUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBordersApi.deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all bidding zone borders
         * @summary Get All Bzb
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBzbBzBordersBiddingZoneBordersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneBordersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBzbBzBordersBiddingZoneBordersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBordersApi.getAllBzbBzBordersBiddingZoneBordersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a bidding zone border by UUID      bzb_uuid: UUID of the bidding zone border to get
         * @summary Get Bzb
         * @param {string} bzbUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneBordersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBordersApi.getBzbBzBordersBiddingZoneBordersBzbUuidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing bidding zone border      bbzb_uuid: UUID of the bidding zone border to update      body: Bidding zone borders update data
         * @summary Update Bzb
         * @param {string} bzbUuid 
         * @param {BiddingZoneBordersUpdate} biddingZoneBordersUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid: string, biddingZoneBordersUpdate: BiddingZoneBordersUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneBordersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid, biddingZoneBordersUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneBordersApi.updateBzbBzBordersBiddingZoneBordersBzbUuidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BidingZoneBordersApi - factory interface
 * @export
 */
export const BidingZoneBordersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BidingZoneBordersApiFp(configuration)
    return {
        /**
         * Create a new bidding zone border      body: Bidding zone borders create data
         * @summary Create Bzb
         * @param {BiddingZoneBordersCreate} biddingZoneBordersCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate: BiddingZoneBordersCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneBordersResponse> {
            return localVarFp.createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a bidding zone border      bzb_uuid: UUID of the bidding zone border to delete
         * @summary Delete Bzb
         * @param {string} bzbUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all bidding zone borders
         * @summary Get All Bzb
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBzbBzBordersBiddingZoneBordersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneBordersResponse>> {
            return localVarFp.getAllBzbBzBordersBiddingZoneBordersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a bidding zone border by UUID      bzb_uuid: UUID of the bidding zone border to get
         * @summary Get Bzb
         * @param {string} bzbUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid: string, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneBordersResponse> {
            return localVarFp.getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing bidding zone border      bbzb_uuid: UUID of the bidding zone border to update      body: Bidding zone borders update data
         * @summary Update Bzb
         * @param {string} bzbUuid 
         * @param {BiddingZoneBordersUpdate} biddingZoneBordersUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid: string, biddingZoneBordersUpdate: BiddingZoneBordersUpdate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneBordersResponse> {
            return localVarFp.updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid, biddingZoneBordersUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BidingZoneBordersApi - interface
 * @export
 * @interface BidingZoneBordersApi
 */
export interface BidingZoneBordersApiInterface {
    /**
     * Create a new bidding zone border      body: Bidding zone borders create data
     * @summary Create Bzb
     * @param {BiddingZoneBordersCreate} biddingZoneBordersCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApiInterface
     */
    createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate: BiddingZoneBordersCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneBordersResponse>;

    /**
     * Delete a bidding zone border      bzb_uuid: UUID of the bidding zone border to delete
     * @summary Delete Bzb
     * @param {string} bzbUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApiInterface
     */
    deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid: string, options?: RawAxiosRequestConfig): AxiosPromise<any>;

    /**
     * Get all bidding zone borders
     * @summary Get All Bzb
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApiInterface
     */
    getAllBzbBzBordersBiddingZoneBordersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneBordersResponse>>;

    /**
     * Get a bidding zone border by UUID      bzb_uuid: UUID of the bidding zone border to get
     * @summary Get Bzb
     * @param {string} bzbUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApiInterface
     */
    getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid: string, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneBordersResponse>;

    /**
     * Update an existing bidding zone border      bbzb_uuid: UUID of the bidding zone border to update      body: Bidding zone borders update data
     * @summary Update Bzb
     * @param {string} bzbUuid 
     * @param {BiddingZoneBordersUpdate} biddingZoneBordersUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApiInterface
     */
    updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid: string, biddingZoneBordersUpdate: BiddingZoneBordersUpdate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneBordersResponse>;

}

/**
 * BidingZoneBordersApi - object-oriented interface
 * @export
 * @class BidingZoneBordersApi
 * @extends {BaseAPI}
 */
export class BidingZoneBordersApi extends BaseAPI implements BidingZoneBordersApiInterface {
    /**
     * Create a new bidding zone border      body: Bidding zone borders create data
     * @summary Create Bzb
     * @param {BiddingZoneBordersCreate} biddingZoneBordersCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApi
     */
    public createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate: BiddingZoneBordersCreate, options?: RawAxiosRequestConfig) {
        return BidingZoneBordersApiFp(this.configuration).createBzbBzBordersBiddingZoneBordersPost(biddingZoneBordersCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a bidding zone border      bzb_uuid: UUID of the bidding zone border to delete
     * @summary Delete Bzb
     * @param {string} bzbUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApi
     */
    public deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid: string, options?: RawAxiosRequestConfig) {
        return BidingZoneBordersApiFp(this.configuration).deleteBzbBzBordersBiddingZoneBordersBzbUuidDelete(bzbUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all bidding zone borders
     * @summary Get All Bzb
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApi
     */
    public getAllBzbBzBordersBiddingZoneBordersGet(options?: RawAxiosRequestConfig) {
        return BidingZoneBordersApiFp(this.configuration).getAllBzbBzBordersBiddingZoneBordersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a bidding zone border by UUID      bzb_uuid: UUID of the bidding zone border to get
     * @summary Get Bzb
     * @param {string} bzbUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApi
     */
    public getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid: string, options?: RawAxiosRequestConfig) {
        return BidingZoneBordersApiFp(this.configuration).getBzbBzBordersBiddingZoneBordersBzbUuidGet(bzbUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing bidding zone border      bbzb_uuid: UUID of the bidding zone border to update      body: Bidding zone borders update data
     * @summary Update Bzb
     * @param {string} bzbUuid 
     * @param {BiddingZoneBordersUpdate} biddingZoneBordersUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneBordersApi
     */
    public updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid: string, biddingZoneBordersUpdate: BiddingZoneBordersUpdate, options?: RawAxiosRequestConfig) {
        return BidingZoneBordersApiFp(this.configuration).updateBzbBzBordersBiddingZoneBordersBzbUuidPut(bzbUuid, biddingZoneBordersUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BidingZoneMappingApi - axios parameter creator
 * @export
 */
export const BidingZoneMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new bidding zone      body: Bidding zone mapping create data
         * @summary Create Bidding Zone
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiddingZoneBzMappingBiddingZonesPost: async (biddingZoneMappingCreate: BiddingZoneMappingCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZoneMappingCreate' is not null or undefined
            assertParamExists('createBiddingZoneBzMappingBiddingZonesPost', 'biddingZoneMappingCreate', biddingZoneMappingCreate)
            const localVarPath = `/bz_mapping/bidding_zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biddingZoneMappingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new bidding zone      body: Bidding zone mapping create data
         * @summary Create Bidding Zone
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiddingZoneBzMappingBiddingZonesPost_1: async (biddingZoneMappingCreate: BiddingZoneMappingCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZoneMappingCreate' is not null or undefined
            assertParamExists('createBiddingZoneBzMappingBiddingZonesPost_1', 'biddingZoneMappingCreate', biddingZoneMappingCreate)
            const localVarPath = `/bz_mapping/bidding_zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biddingZoneMappingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all bidding zones
         * @summary Get All Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllZonesBzMappingZonesListGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bz_mapping/zones/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all bidding zones
         * @summary Get All Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllZonesBzMappingZonesListGet_2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bz_mapping/zones/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get non-virtual bidding zones
         * @summary Read Non Virtual Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNonVirtualZonesBzMappingActiveZonesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bz_mapping/active_zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get non-virtual bidding zones
         * @summary Read Non Virtual Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNonVirtualZonesBzMappingActiveZonesGet_3: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bz_mapping/active_zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
         * @summary Update Bidding Zone
         * @param {string} bzUuid 
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBiddingZoneBzMappingZonesBzUuidPut: async (bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bzUuid' is not null or undefined
            assertParamExists('updateBiddingZoneBzMappingZonesBzUuidPut', 'bzUuid', bzUuid)
            // verify required parameter 'biddingZoneMappingCreate' is not null or undefined
            assertParamExists('updateBiddingZoneBzMappingZonesBzUuidPut', 'biddingZoneMappingCreate', biddingZoneMappingCreate)
            const localVarPath = `/bz_mapping/zones/{bz_uuid}`
                .replace(`{${"bz_uuid"}}`, encodeURIComponent(String(bzUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biddingZoneMappingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
         * @summary Update Bidding Zone
         * @param {string} bzUuid 
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBiddingZoneBzMappingZonesBzUuidPut_4: async (bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bzUuid' is not null or undefined
            assertParamExists('updateBiddingZoneBzMappingZonesBzUuidPut_4', 'bzUuid', bzUuid)
            // verify required parameter 'biddingZoneMappingCreate' is not null or undefined
            assertParamExists('updateBiddingZoneBzMappingZonesBzUuidPut_4', 'biddingZoneMappingCreate', biddingZoneMappingCreate)
            const localVarPath = `/bz_mapping/zones/{bz_uuid}`
                .replace(`{${"bz_uuid"}}`, encodeURIComponent(String(bzUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(biddingZoneMappingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidingZoneMappingApi - functional programming interface
 * @export
 */
export const BidingZoneMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BidingZoneMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new bidding zone      body: Bidding zone mapping create data
         * @summary Create Bidding Zone
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.createBiddingZoneBzMappingBiddingZonesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new bidding zone      body: Bidding zone mapping create data
         * @summary Create Bidding Zone
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.createBiddingZoneBzMappingBiddingZonesPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all bidding zones
         * @summary Get All Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllZonesBzMappingZonesListGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneMappingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllZonesBzMappingZonesListGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.getAllZonesBzMappingZonesListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all bidding zones
         * @summary Get All Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllZonesBzMappingZonesListGet_2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneMappingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllZonesBzMappingZonesListGet_2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.getAllZonesBzMappingZonesListGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get non-virtual bidding zones
         * @summary Read Non Virtual Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNonVirtualZonesBzMappingActiveZonesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNonVirtualZonesBzMappingActiveZonesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.readNonVirtualZonesBzMappingActiveZonesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get non-virtual bidding zones
         * @summary Read Non Virtual Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNonVirtualZonesBzMappingActiveZonesGet_3(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNonVirtualZonesBzMappingActiveZonesGet_3(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.readNonVirtualZonesBzMappingActiveZonesGet_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
         * @summary Update Bidding Zone
         * @param {string} bzUuid 
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid, biddingZoneMappingCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.updateBiddingZoneBzMappingZonesBzUuidPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
         * @summary Update Bidding Zone
         * @param {string} bzUuid 
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingZoneMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid, biddingZoneMappingCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneMappingApi.updateBiddingZoneBzMappingZonesBzUuidPut_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BidingZoneMappingApi - factory interface
 * @export
 */
export const BidingZoneMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BidingZoneMappingApiFp(configuration)
    return {
        /**
         * Create a new bidding zone      body: Bidding zone mapping create data
         * @summary Create Bidding Zone
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse> {
            return localVarFp.createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new bidding zone      body: Bidding zone mapping create data
         * @summary Create Bidding Zone
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse> {
            return localVarFp.createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all bidding zones
         * @summary Get All Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllZonesBzMappingZonesListGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneMappingResponse>> {
            return localVarFp.getAllZonesBzMappingZonesListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all bidding zones
         * @summary Get All Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllZonesBzMappingZonesListGet_2(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneMappingResponse>> {
            return localVarFp.getAllZonesBzMappingZonesListGet_2(options).then((request) => request(axios, basePath));
        },
        /**
         * Get non-virtual bidding zones
         * @summary Read Non Virtual Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNonVirtualZonesBzMappingActiveZonesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.readNonVirtualZonesBzMappingActiveZonesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get non-virtual bidding zones
         * @summary Read Non Virtual Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNonVirtualZonesBzMappingActiveZonesGet_3(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.readNonVirtualZonesBzMappingActiveZonesGet_3(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
         * @summary Update Bidding Zone
         * @param {string} bzUuid 
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse> {
            return localVarFp.updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid, biddingZoneMappingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
         * @summary Update Bidding Zone
         * @param {string} bzUuid 
         * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse> {
            return localVarFp.updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid, biddingZoneMappingCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BidingZoneMappingApi - interface
 * @export
 * @interface BidingZoneMappingApi
 */
export interface BidingZoneMappingApiInterface {
    /**
     * Create a new bidding zone      body: Bidding zone mapping create data
     * @summary Create Bidding Zone
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse>;

    /**
     * Create a new bidding zone      body: Bidding zone mapping create data
     * @summary Create Bidding Zone
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse>;

    /**
     * Get all bidding zones
     * @summary Get All Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    getAllZonesBzMappingZonesListGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneMappingResponse>>;

    /**
     * Get all bidding zones
     * @summary Get All Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    getAllZonesBzMappingZonesListGet_2(options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneMappingResponse>>;

    /**
     * Get non-virtual bidding zones
     * @summary Read Non Virtual Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    readNonVirtualZonesBzMappingActiveZonesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * Get non-virtual bidding zones
     * @summary Read Non Virtual Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    readNonVirtualZonesBzMappingActiveZonesGet_3(options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
     * @summary Update Bidding Zone
     * @param {string} bzUuid 
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse>;

    /**
     * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
     * @summary Update Bidding Zone
     * @param {string} bzUuid 
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApiInterface
     */
    updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig): AxiosPromise<BiddingZoneMappingResponse>;

}

/**
 * BidingZoneMappingApi - object-oriented interface
 * @export
 * @class BidingZoneMappingApi
 * @extends {BaseAPI}
 */
export class BidingZoneMappingApi extends BaseAPI implements BidingZoneMappingApiInterface {
    /**
     * Create a new bidding zone      body: Bidding zone mapping create data
     * @summary Create Bidding Zone
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).createBiddingZoneBzMappingBiddingZonesPost(biddingZoneMappingCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new bidding zone      body: Bidding zone mapping create data
     * @summary Create Bidding Zone
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).createBiddingZoneBzMappingBiddingZonesPost_1(biddingZoneMappingCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all bidding zones
     * @summary Get All Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public getAllZonesBzMappingZonesListGet(options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).getAllZonesBzMappingZonesListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all bidding zones
     * @summary Get All Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public getAllZonesBzMappingZonesListGet_2(options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).getAllZonesBzMappingZonesListGet_2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get non-virtual bidding zones
     * @summary Read Non Virtual Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public readNonVirtualZonesBzMappingActiveZonesGet(options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).readNonVirtualZonesBzMappingActiveZonesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get non-virtual bidding zones
     * @summary Read Non Virtual Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public readNonVirtualZonesBzMappingActiveZonesGet_3(options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).readNonVirtualZonesBzMappingActiveZonesGet_3(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
     * @summary Update Bidding Zone
     * @param {string} bzUuid 
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).updateBiddingZoneBzMappingZonesBzUuidPut(bzUuid, biddingZoneMappingCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a bidding zone      bz_uuid: UUID of the bidding zone to update      body: Bidding zone mapping create data
     * @summary Update Bidding Zone
     * @param {string} bzUuid 
     * @param {BiddingZoneMappingCreate} biddingZoneMappingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneMappingApi
     */
    public updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid: string, biddingZoneMappingCreate: BiddingZoneMappingCreate, options?: RawAxiosRequestConfig) {
        return BidingZoneMappingApiFp(this.configuration).updateBiddingZoneBzMappingZonesBzUuidPut_4(bzUuid, biddingZoneMappingCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BidingZoneVariablesApi - axios parameter creator
 * @export
 */
export const BidingZoneVariablesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Average net position for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
         * @summary Average Net Position
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        averageNetPositionBzVariablesNetPositionsAverageGet: async (startTime: string, endTime: string, biddingZone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('averageNetPositionBzVariablesNetPositionsAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('averageNetPositionBzVariablesNetPositionsAverageGet', 'endTime', endTime)
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('averageNetPositionBzVariablesNetPositionsAverageGet', 'biddingZone', biddingZone)
            const localVarPath = `/bz_variables/net_positions/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Average prices for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
         * @summary Average Prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        averagePricesBzVariablesPricesAverageGet: async (startTime: string, endTime: string, biddingZone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('averagePricesBzVariablesPricesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('averagePricesBzVariablesPricesAverageGet', 'endTime', endTime)
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('averagePricesBzVariablesPricesAverageGet', 'biddingZone', biddingZone)
            const localVarPath = `/bz_variables/prices/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BidingZoneVariablesApi - functional programming interface
 * @export
 */
export const BidingZoneVariablesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BidingZoneVariablesApiAxiosParamCreator(configuration)
    return {
        /**
         * Average net position for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
         * @summary Average Net Position
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async averageNetPositionBzVariablesNetPositionsAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BZVariablesAverageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.averageNetPositionBzVariablesNetPositionsAverageGet(startTime, endTime, biddingZone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneVariablesApi.averageNetPositionBzVariablesNetPositionsAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Average prices for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
         * @summary Average Prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async averagePricesBzVariablesPricesAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BZVariablesAverageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.averagePricesBzVariablesPricesAverageGet(startTime, endTime, biddingZone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BidingZoneVariablesApi.averagePricesBzVariablesPricesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BidingZoneVariablesApi - factory interface
 * @export
 */
export const BidingZoneVariablesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BidingZoneVariablesApiFp(configuration)
    return {
        /**
         * Average net position for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
         * @summary Average Net Position
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        averageNetPositionBzVariablesNetPositionsAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig): AxiosPromise<BZVariablesAverageResponse> {
            return localVarFp.averageNetPositionBzVariablesNetPositionsAverageGet(startTime, endTime, biddingZone, options).then((request) => request(axios, basePath));
        },
        /**
         * Average prices for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
         * @summary Average Prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} biddingZone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        averagePricesBzVariablesPricesAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig): AxiosPromise<BZVariablesAverageResponse> {
            return localVarFp.averagePricesBzVariablesPricesAverageGet(startTime, endTime, biddingZone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BidingZoneVariablesApi - interface
 * @export
 * @interface BidingZoneVariablesApi
 */
export interface BidingZoneVariablesApiInterface {
    /**
     * Average net position for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
     * @summary Average Net Position
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneVariablesApiInterface
     */
    averageNetPositionBzVariablesNetPositionsAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig): AxiosPromise<BZVariablesAverageResponse>;

    /**
     * Average prices for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
     * @summary Average Prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneVariablesApiInterface
     */
    averagePricesBzVariablesPricesAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig): AxiosPromise<BZVariablesAverageResponse>;

}

/**
 * BidingZoneVariablesApi - object-oriented interface
 * @export
 * @class BidingZoneVariablesApi
 * @extends {BaseAPI}
 */
export class BidingZoneVariablesApi extends BaseAPI implements BidingZoneVariablesApiInterface {
    /**
     * Average net position for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
     * @summary Average Net Position
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneVariablesApi
     */
    public averageNetPositionBzVariablesNetPositionsAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig) {
        return BidingZoneVariablesApiFp(this.configuration).averageNetPositionBzVariablesNetPositionsAverageGet(startTime, endTime, biddingZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Average prices for a given time period and bidding zone      start_time: Start time of the time period      end_time: End time of the time period (must be after start time)      bidding_zone: Bidding zone
     * @summary Average Prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} biddingZone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BidingZoneVariablesApi
     */
    public averagePricesBzVariablesPricesAverageGet(startTime: string, endTime: string, biddingZone: string, options?: RawAxiosRequestConfig) {
        return BidingZoneVariablesApiFp(this.configuration).averagePricesBzVariablesPricesAverageGet(startTime, endTime, biddingZone, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DayAheadNetPositionApi - axios parameter creator
 * @export
 */
export const DayAheadNetPositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average day ahead net positions for all bidding zones
         * @summary Get average day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet', 'endTime', endTime)
            const localVarPath = `/day_ahead/net_positions/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average day ahead net positions for all bidding zones
         * @summary Get average day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1', 'endTime', endTime)
            const localVarPath = `/day_ahead/net_positions/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for day ahead net positions for a given time interval
         * @summary Get data availability percentage for day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/day_ahead/net_positions/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
         * @summary Filter day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadNetPositionsDayAheadNetPositionsFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadNetPositionsDayAheadNetPositionsFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadNetPositionsDayAheadNetPositionsFilterGet', 'endTime', endTime)
            const localVarPath = `/day_ahead/net_positions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
         * @summary Filter day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2', 'endTime', endTime)
            const localVarPath = `/day_ahead/net_positions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get day ahead net positions by zone
         * @summary Get day ahead net positions by zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/day_ahead/net_positions/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get day ahead net positions by zone
         * @summary Get day ahead net positions by zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3', 'biddingZone', biddingZone)
            const localVarPath = `/day_ahead/net_positions/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DayAheadNetPositionApi - functional programming interface
 * @export
 */
export const DayAheadNetPositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DayAheadNetPositionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average day ahead net positions for all bidding zones
         * @summary Get average day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average day ahead net positions for all bidding zones
         * @summary Get average day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for day ahead net positions for a given time interval
         * @summary Get data availability percentage for day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
         * @summary Filter day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getDayAheadNetPositionsDayAheadNetPositionsFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
         * @summary Filter day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get day ahead net positions by zone
         * @summary Get day ahead net positions by zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get day ahead net positions by zone
         * @summary Get day ahead net positions by zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadNetPositionApi.getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DayAheadNetPositionApi - factory interface
 * @export
 */
export const DayAheadNetPositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DayAheadNetPositionApiFp(configuration)
    return {
        /**
         * Get average day ahead net positions for all bidding zones
         * @summary Get average day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average day ahead net positions for all bidding zones
         * @summary Get average day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for day ahead net positions for a given time interval
         * @summary Get data availability percentage for day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
         * @summary Filter day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
         * @summary Filter day ahead net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get day ahead net positions by zone
         * @summary Get day ahead net positions by zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get day ahead net positions by zone
         * @summary Get day ahead net positions by zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DayAheadNetPositionApi - interface
 * @export
 * @interface DayAheadNetPositionApi
 */
export interface DayAheadNetPositionApiInterface {
    /**
     * Get average day ahead net positions for all bidding zones
     * @summary Get average day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get average day ahead net positions for all bidding zones
     * @summary Get average day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get data availability percentage for day ahead net positions for a given time interval
     * @summary Get data availability percentage for day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
     * @summary Filter day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
     * @summary Filter day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get day ahead net positions by zone
     * @summary Get day ahead net positions by zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get day ahead net positions by zone
     * @summary Get day ahead net positions by zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApiInterface
     */
    getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

}

/**
 * DayAheadNetPositionApi - object-oriented interface
 * @export
 * @class DayAheadNetPositionApi
 * @extends {BaseAPI}
 */
export class DayAheadNetPositionApi extends BaseAPI implements DayAheadNetPositionApiInterface {
    /**
     * Get average day ahead net positions for all bidding zones
     * @summary Get average day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average day ahead net positions for all bidding zones
     * @summary Get average day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getAverageDayAheadNetPositionsDayAheadNetPositionsAverageGet_1(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for day ahead net positions for a given time interval
     * @summary Get data availability percentage for day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getDaDataAvailabilityDayAheadNetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
     * @summary Filter day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getDayAheadNetPositionsDayAheadNetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter day ahead net positions for a given (optional) bidding zone, and time interval and return net positions
     * @summary Filter day ahead net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getDayAheadNetPositionsDayAheadNetPositionsFilterGet_2(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get day ahead net positions by zone
     * @summary Get day ahead net positions by zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get day ahead net positions by zone
     * @summary Get day ahead net positions by zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadNetPositionApi
     */
    public getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadNetPositionApiFp(this.configuration).getNetPositionsByZoneDayAheadNetPositionsBiddingZoneGet_3(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DayAheadPriceApi - axios parameter creator
 * @export
 */
export const DayAheadPriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average day ahead prices for all bidding zones      start_time: Start time of the time interval      end_time: End time of the time interval (must be after start time)
         * @summary Get Average Day Ahead Prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPricesDayAheadPricesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPricesDayAheadPricesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPricesDayAheadPricesAverageGet', 'endTime', endTime)
            const localVarPath = `/day_ahead/prices/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for day ahead prices for a given time interval
         * @summary Get data availability percentage for day ahead prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDaDataAvailabilityDayAheadPricesDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDaDataAvailabilityDayAheadPricesDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDaDataAvailabilityDayAheadPricesDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDaDataAvailabilityDayAheadPricesDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/day_ahead/prices/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter market prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPricesDayAheadPricesFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPricesDayAheadPricesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPricesDayAheadPricesFilterGet', 'endTime', endTime)
            const localVarPath = `/day_ahead/prices/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get day ahead prices for a specific bidding zone      bidding_zone: Bidding zone      limit: Limit the number of results
         * @summary Get Prices By Zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesByZoneDayAheadPricesBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getPricesByZoneDayAheadPricesBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/day_ahead/prices/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DayAheadPriceApi - functional programming interface
 * @export
 */
export const DayAheadPriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DayAheadPriceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average day ahead prices for all bidding zones      start_time: Start time of the time interval      end_time: End time of the time interval (must be after start time)
         * @summary Get Average Day Ahead Prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPricesDayAheadPricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPricesDayAheadPricesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceApi.getAverageDayAheadPricesDayAheadPricesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for day ahead prices for a given time interval
         * @summary Get data availability percentage for day ahead prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceApi.getDaDataAvailabilityDayAheadPricesDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter market prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPricesDayAheadPricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPricesDayAheadPricesFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceApi.getDayAheadPricesDayAheadPricesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get day ahead prices for a specific bidding zone      bidding_zone: Bidding zone      limit: Limit the number of results
         * @summary Get Prices By Zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceApi.getPricesByZoneDayAheadPricesBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DayAheadPriceApi - factory interface
 * @export
 */
export const DayAheadPriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DayAheadPriceApiFp(configuration)
    return {
        /**
         * Get average day ahead prices for all bidding zones      start_time: Start time of the time interval      end_time: End time of the time interval (must be after start time)
         * @summary Get Average Day Ahead Prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPricesDayAheadPricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getAverageDayAheadPricesDayAheadPricesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for day ahead prices for a given time interval
         * @summary Get data availability percentage for day ahead prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter market prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPricesDayAheadPricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getDayAheadPricesDayAheadPricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get day ahead prices for a specific bidding zone      bidding_zone: Bidding zone      limit: Limit the number of results
         * @summary Get Prices By Zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DayAheadPriceApi - interface
 * @export
 * @interface DayAheadPriceApi
 */
export interface DayAheadPriceApiInterface {
    /**
     * Get average day ahead prices for all bidding zones      start_time: Start time of the time interval      end_time: End time of the time interval (must be after start time)
     * @summary Get Average Day Ahead Prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApiInterface
     */
    getAverageDayAheadPricesDayAheadPricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Get data availability percentage for day ahead prices for a given time interval
     * @summary Get data availability percentage for day ahead prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApiInterface
     */
    getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter market prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApiInterface
     */
    getDayAheadPricesDayAheadPricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Get day ahead prices for a specific bidding zone      bidding_zone: Bidding zone      limit: Limit the number of results
     * @summary Get Prices By Zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApiInterface
     */
    getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

}

/**
 * DayAheadPriceApi - object-oriented interface
 * @export
 * @class DayAheadPriceApi
 * @extends {BaseAPI}
 */
export class DayAheadPriceApi extends BaseAPI implements DayAheadPriceApiInterface {
    /**
     * Get average day ahead prices for all bidding zones      start_time: Start time of the time interval      end_time: End time of the time interval (must be after start time)
     * @summary Get Average Day Ahead Prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApi
     */
    public getAverageDayAheadPricesDayAheadPricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return DayAheadPriceApiFp(this.configuration).getAverageDayAheadPricesDayAheadPricesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for day ahead prices for a given time interval
     * @summary Get data availability percentage for day ahead prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApi
     */
    public getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return DayAheadPriceApiFp(this.configuration).getDaDataAvailabilityDayAheadPricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter market prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApi
     */
    public getDayAheadPricesDayAheadPricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadPriceApiFp(this.configuration).getDayAheadPricesDayAheadPricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get day ahead prices for a specific bidding zone      bidding_zone: Bidding zone      limit: Limit the number of results
     * @summary Get Prices By Zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceApi
     */
    public getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadPriceApiFp(this.configuration).getPricesByZoneDayAheadPricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DayAheadPriceSpreadApi - axios parameter creator
 * @export
 */
export const DayAheadPriceSpreadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average day ahead price spread for all bidding zone borders in a given time interval
         * @summary Get average day ahead price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet', 'interval', interval)
            const localVarPath = `/day_ahead/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average day ahead price spread for all bidding zone borders in a given time interval
         * @summary Get average day ahead price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1', 'interval', interval)
            const localVarPath = `/day_ahead/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get day ahead price spreads for all bidding zone borders in a given time interval
         * @summary Get day ahead price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet', 'interval', interval)
            const localVarPath = `/day_ahead/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get day ahead price spreads for all bidding zone borders in a given time interval
         * @summary Get day ahead price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2', 'interval', interval)
            const localVarPath = `/day_ahead/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DayAheadPriceSpreadApi - functional programming interface
 * @export
 */
export const DayAheadPriceSpreadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DayAheadPriceSpreadApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average day ahead price spread for all bidding zone borders in a given time interval
         * @summary Get average day ahead price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceSpreadApi.getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average day ahead price spread for all bidding zone borders in a given time interval
         * @summary Get average day ahead price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceSpreadApi.getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get day ahead price spreads for all bidding zone borders in a given time interval
         * @summary Get day ahead price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceSpreadApi.getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get day ahead price spreads for all bidding zone borders in a given time interval
         * @summary Get day ahead price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadPriceSpreadApi.getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DayAheadPriceSpreadApi - factory interface
 * @export
 */
export const DayAheadPriceSpreadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DayAheadPriceSpreadApiFp(configuration)
    return {
        /**
         * Get average day ahead price spread for all bidding zone borders in a given time interval
         * @summary Get average day ahead price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average day ahead price spread for all bidding zone borders in a given time interval
         * @summary Get average day ahead price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get day ahead price spreads for all bidding zone borders in a given time interval
         * @summary Get day ahead price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get day ahead price spreads for all bidding zone borders in a given time interval
         * @summary Get day ahead price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DayAheadPriceSpreadApi - interface
 * @export
 * @interface DayAheadPriceSpreadApi
 */
export interface DayAheadPriceSpreadApiInterface {
    /**
     * Get average day ahead price spread for all bidding zone borders in a given time interval
     * @summary Get average day ahead price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get average day ahead price spread for all bidding zone borders in a given time interval
     * @summary Get average day ahead price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get day ahead price spreads for all bidding zone borders in a given time interval
     * @summary Get day ahead price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get day ahead price spreads for all bidding zone borders in a given time interval
     * @summary Get day ahead price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

}

/**
 * DayAheadPriceSpreadApi - object-oriented interface
 * @export
 * @class DayAheadPriceSpreadApi
 * @extends {BaseAPI}
 */
export class DayAheadPriceSpreadApi extends BaseAPI implements DayAheadPriceSpreadApiInterface {
    /**
     * Get average day ahead price spread for all bidding zone borders in a given time interval
     * @summary Get average day ahead price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return DayAheadPriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average day ahead price spread for all bidding zone borders in a given time interval
     * @summary Get average day ahead price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return DayAheadPriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadDayAheadPriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get day ahead price spreads for all bidding zone borders in a given time interval
     * @summary Get day ahead price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApi
     */
    public getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return DayAheadPriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get day ahead price spreads for all bidding zone borders in a given time interval
     * @summary Get day ahead price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadPriceSpreadApi
     */
    public getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return DayAheadPriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsDayAheadPriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DayAheadScheduledCommercialExchangesApi - axios parameter creator
 * @export
 */
export const DayAheadScheduledCommercialExchangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet', 'endTime', endTime)
            const localVarPath = `/day_ahead/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1', 'endTime', endTime)
            const localVarPath = `/day_ahead/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
         * @summary Filter day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZoneFrom] 
         * @param {string | null} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet: async (startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet', 'endTime', endTime)
            const localVarPath = `/day_ahead/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
         * @summary Filter day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZoneFrom] 
         * @param {string | null} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2: async (startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2', 'endTime', endTime)
            const localVarPath = `/day_ahead/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DayAheadScheduledCommercialExchangesApi - functional programming interface
 * @export
 */
export const DayAheadScheduledCommercialExchangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DayAheadScheduledCommercialExchangesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadScheduledCommercialExchangesApi.getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadScheduledCommercialExchangesApi.getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
         * @summary Filter day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZoneFrom] 
         * @param {string | null} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadScheduledCommercialExchangesApi.getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
         * @summary Filter day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZoneFrom] 
         * @param {string | null} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DayAheadScheduledCommercialExchangesApi.getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DayAheadScheduledCommercialExchangesApi - factory interface
 * @export
 */
export const DayAheadScheduledCommercialExchangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DayAheadScheduledCommercialExchangesApiFp(configuration)
    return {
        /**
         * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
         * @summary Filter day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZoneFrom] 
         * @param {string | null} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
         * @summary Filter day ahead scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZoneFrom] 
         * @param {string | null} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DayAheadScheduledCommercialExchangesApi - interface
 * @export
 * @interface DayAheadScheduledCommercialExchangesApi
 */
export interface DayAheadScheduledCommercialExchangesApiInterface {
    /**
     * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApiInterface
     */
    getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApiInterface
     */
    getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
     * @summary Filter day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZoneFrom] 
     * @param {string | null} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApiInterface
     */
    getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
     * @summary Filter day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZoneFrom] 
     * @param {string | null} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApiInterface
     */
    getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

}

/**
 * DayAheadScheduledCommercialExchangesApi - object-oriented interface
 * @export
 * @class DayAheadScheduledCommercialExchangesApi
 * @extends {BaseAPI}
 */
export class DayAheadScheduledCommercialExchangesApi extends BaseAPI implements DayAheadScheduledCommercialExchangesApiInterface {
    /**
     * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApi
     */
    public getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return DayAheadScheduledCommercialExchangesApiFp(this.configuration).getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average day ahead scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApi
     */
    public getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return DayAheadScheduledCommercialExchangesApiFp(this.configuration).getAverageDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
     * @summary Filter day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZoneFrom] 
     * @param {string | null} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApi
     */
    public getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadScheduledCommercialExchangesApiFp(this.configuration).getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter day ahead scheduled commercial exchanges for a given (optional) from and to bidding zone, and time interval
     * @summary Filter day ahead scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZoneFrom] 
     * @param {string | null} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DayAheadScheduledCommercialExchangesApi
     */
    public getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string | null, biddingZoneTo?: string | null, limit?: number, options?: RawAxiosRequestConfig) {
        return DayAheadScheduledCommercialExchangesApiFp(this.configuration).getDayAheadScheduledCommercialExchangesDayAheadScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday1NetPositionApi - axios parameter creator
 * @export
 */
export const Intraday1NetPositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 1 net positions for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageNetPositionsIda1NetPositionsAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageNetPositionsIda1NetPositionsAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageNetPositionsIda1NetPositionsAverageGet', 'endTime', endTime)
            const localVarPath = `/ida1/net_positions/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for intraday 1 net positions for a given time interval
         * @summary Get data availability percentage for intraday 1 net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/ida1/net_positions/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 1 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter intraday 1 net positions between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1NetPositionsIda1NetPositionsFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda1NetPositionsIda1NetPositionsFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda1NetPositionsIda1NetPositionsFilterGet', 'endTime', endTime)
            const localVarPath = `/ida1/net_positions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 1 net positions for a given bidding zone
         * @summary Get intraday 1 net positions for a given bidding zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneIda1NetPositionsBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getNetPositionsByZoneIda1NetPositionsBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/ida1/net_positions/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday1NetPositionApi - functional programming interface
 * @export
 */
export const Intraday1NetPositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday1NetPositionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 1 net positions for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageNetPositionsIda1NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageNetPositionsIda1NetPositionsAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1NetPositionApi.getAverageNetPositionsIda1NetPositionsAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for intraday 1 net positions for a given time interval
         * @summary Get data availability percentage for intraday 1 net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1NetPositionApi.getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 1 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter intraday 1 net positions between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1NetPositionsIda1NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1NetPositionsIda1NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1NetPositionApi.getIda1NetPositionsIda1NetPositionsFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 1 net positions for a given bidding zone
         * @summary Get intraday 1 net positions for a given bidding zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1NetPositionApi.getNetPositionsByZoneIda1NetPositionsBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday1NetPositionApi - factory interface
 * @export
 */
export const Intraday1NetPositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday1NetPositionApiFp(configuration)
    return {
        /**
         * Get average intraday 1 net positions for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageNetPositionsIda1NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getAverageNetPositionsIda1NetPositionsAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for intraday 1 net positions for a given time interval
         * @summary Get data availability percentage for intraday 1 net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 1 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter intraday 1 net positions between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1NetPositionsIda1NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getIda1NetPositionsIda1NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 1 net positions for a given bidding zone
         * @summary Get intraday 1 net positions for a given bidding zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday1NetPositionApi - interface
 * @export
 * @interface Intraday1NetPositionApi
 */
export interface Intraday1NetPositionApiInterface {
    /**
     * Get average intraday 1 net positions for a given time interval
     * @summary Get average net positions for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApiInterface
     */
    getAverageNetPositionsIda1NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get data availability percentage for intraday 1 net positions for a given time interval
     * @summary Get data availability percentage for intraday 1 net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApiInterface
     */
    getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Filter intraday 1 net positions for a given bidding zone and time interval and return net positions
     * @summary Filter intraday 1 net positions between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApiInterface
     */
    getIda1NetPositionsIda1NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get intraday 1 net positions for a given bidding zone
     * @summary Get intraday 1 net positions for a given bidding zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApiInterface
     */
    getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

}

/**
 * Intraday1NetPositionApi - object-oriented interface
 * @export
 * @class Intraday1NetPositionApi
 * @extends {BaseAPI}
 */
export class Intraday1NetPositionApi extends BaseAPI implements Intraday1NetPositionApiInterface {
    /**
     * Get average intraday 1 net positions for a given time interval
     * @summary Get average net positions for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApi
     */
    public getAverageNetPositionsIda1NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday1NetPositionApiFp(this.configuration).getAverageNetPositionsIda1NetPositionsAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for intraday 1 net positions for a given time interval
     * @summary Get data availability percentage for intraday 1 net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApi
     */
    public getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday1NetPositionApiFp(this.configuration).getIda1DataAvailabilityIda1NetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 1 net positions for a given bidding zone and time interval and return net positions
     * @summary Filter intraday 1 net positions between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApi
     */
    public getIda1NetPositionsIda1NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday1NetPositionApiFp(this.configuration).getIda1NetPositionsIda1NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 1 net positions for a given bidding zone
     * @summary Get intraday 1 net positions for a given bidding zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1NetPositionApi
     */
    public getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday1NetPositionApiFp(this.configuration).getNetPositionsByZoneIda1NetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday1PriceSpreadApi - axios parameter creator
 * @export
 */
export const Intraday1PriceSpreadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 1 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 1 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet', 'interval', interval)
            const localVarPath = `/ida1/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average intraday 1 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 1 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1', 'interval', interval)
            const localVarPath = `/ida1/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 1 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 1 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda1PriceSpreadFilterGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda1PriceSpreadFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda1PriceSpreadFilterGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda1PriceSpreadFilterGet', 'interval', interval)
            const localVarPath = `/ida1/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 1 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 1 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2', 'interval', interval)
            const localVarPath = `/ida1/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday1PriceSpreadApi - functional programming interface
 * @export
 */
export const Intraday1PriceSpreadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday1PriceSpreadApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 1 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 1 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PriceSpreadApi.getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average intraday 1 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 1 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PriceSpreadApi.getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 1 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 1 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PriceSpreadApi.getDayAheadPriceSpreadsIda1PriceSpreadFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 1 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 1 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PriceSpreadApi.getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday1PriceSpreadApi - factory interface
 * @export
 */
export const Intraday1PriceSpreadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday1PriceSpreadApiFp(configuration)
    return {
        /**
         * Get average intraday 1 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 1 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average intraday 1 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 1 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 1 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 1 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 1 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 1 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday1PriceSpreadApi - interface
 * @export
 * @interface Intraday1PriceSpreadApi
 */
export interface Intraday1PriceSpreadApiInterface {
    /**
     * Get average intraday 1 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 1 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get average intraday 1 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 1 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get intraday 1 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 1 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get intraday 1 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 1 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

}

/**
 * Intraday1PriceSpreadApi - object-oriented interface
 * @export
 * @class Intraday1PriceSpreadApi
 * @extends {BaseAPI}
 */
export class Intraday1PriceSpreadApi extends BaseAPI implements Intraday1PriceSpreadApiInterface {
    /**
     * Get average intraday 1 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 1 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday1PriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average intraday 1 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 1 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday1PriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadIda1PriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 1 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 1 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApi
     */
    public getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday1PriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsIda1PriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 1 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 1 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PriceSpreadApi
     */
    public getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday1PriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsIda1PriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday1PricesApi - axios parameter creator
 * @export
 */
export const Intraday1PricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Query between two intervals returns the average prices of all biding zones
         * @summary Get average intraday 1 prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda1PricesIda1PricesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda1PricesIda1PricesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda1PricesIda1PricesAverageGet', 'endTime', endTime)
            const localVarPath = `/ida1/prices/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for intraday 1 prices for a given time interval
         * @summary Get data availability percentage for intraday 1 prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1DataAvailabilityIda1PricesDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda1DataAvailabilityIda1PricesDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda1DataAvailabilityIda1PricesDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getIda1DataAvailabilityIda1PricesDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/ida1/prices/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query between two intervals returns the prices of a specific bidding zone
         * @summary Get intraday 1 prices for a given bidding zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1PricesByZoneIda1PricesBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getIda1PricesByZoneIda1PricesBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/ida1/prices/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday 1 prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1PricesIda1PricesFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda1PricesIda1PricesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda1PricesIda1PricesFilterGet', 'endTime', endTime)
            const localVarPath = `/ida1/prices/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday1PricesApi - functional programming interface
 * @export
 */
export const Intraday1PricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday1PricesApiAxiosParamCreator(configuration)
    return {
        /**
         * Query between two intervals returns the average prices of all biding zones
         * @summary Get average intraday 1 prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda1PricesIda1PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda1PricesIda1PricesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PricesApi.getAverageIda1PricesIda1PricesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for intraday 1 prices for a given time interval
         * @summary Get data availability percentage for intraday 1 prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PricesApi.getIda1DataAvailabilityIda1PricesDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query between two intervals returns the prices of a specific bidding zone
         * @summary Get intraday 1 prices for a given bidding zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PricesApi.getIda1PricesByZoneIda1PricesBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday 1 prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1PricesIda1PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1PricesIda1PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1PricesApi.getIda1PricesIda1PricesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday1PricesApi - factory interface
 * @export
 */
export const Intraday1PricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday1PricesApiFp(configuration)
    return {
        /**
         * Query between two intervals returns the average prices of all biding zones
         * @summary Get average intraday 1 prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda1PricesIda1PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getAverageIda1PricesIda1PricesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for intraday 1 prices for a given time interval
         * @summary Get data availability percentage for intraday 1 prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Query between two intervals returns the prices of a specific bidding zone
         * @summary Get intraday 1 prices for a given bidding zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday 1 prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1PricesIda1PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda1PricesIda1PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday1PricesApi - interface
 * @export
 * @interface Intraday1PricesApi
 */
export interface Intraday1PricesApiInterface {
    /**
     * Query between two intervals returns the average prices of all biding zones
     * @summary Get average intraday 1 prices for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApiInterface
     */
    getAverageIda1PricesIda1PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Get data availability percentage for intraday 1 prices for a given time interval
     * @summary Get data availability percentage for intraday 1 prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApiInterface
     */
    getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Query between two intervals returns the prices of a specific bidding zone
     * @summary Get intraday 1 prices for a given bidding zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApiInterface
     */
    getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter intraday 1 prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApiInterface
     */
    getIda1PricesIda1PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

}

/**
 * Intraday1PricesApi - object-oriented interface
 * @export
 * @class Intraday1PricesApi
 * @extends {BaseAPI}
 */
export class Intraday1PricesApi extends BaseAPI implements Intraday1PricesApiInterface {
    /**
     * Query between two intervals returns the average prices of all biding zones
     * @summary Get average intraday 1 prices for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApi
     */
    public getAverageIda1PricesIda1PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday1PricesApiFp(this.configuration).getAverageIda1PricesIda1PricesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for intraday 1 prices for a given time interval
     * @summary Get data availability percentage for intraday 1 prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApi
     */
    public getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday1PricesApiFp(this.configuration).getIda1DataAvailabilityIda1PricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query between two intervals returns the prices of a specific bidding zone
     * @summary Get intraday 1 prices for a given bidding zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApi
     */
    public getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday1PricesApiFp(this.configuration).getIda1PricesByZoneIda1PricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter intraday 1 prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1PricesApi
     */
    public getIda1PricesIda1PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday1PricesApiFp(this.configuration).getIda1PricesIda1PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday1ScheduledCommercialExchangesApi - axios parameter creator
 * @export
 */
export const Intraday1ScheduledCommercialExchangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet', 'endTime', endTime)
            const localVarPath = `/ida1/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1', 'endTime', endTime)
            const localVarPath = `/ida1/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet: async (startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet', 'endTime', endTime)
            const localVarPath = `/ida1/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2: async (startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2', 'endTime', endTime)
            const localVarPath = `/ida1/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday1ScheduledCommercialExchangesApi - functional programming interface
 * @export
 */
export const Intraday1ScheduledCommercialExchangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday1ScheduledCommercialExchangesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1ScheduledCommercialExchangesApi.getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1ScheduledCommercialExchangesApi.getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1ScheduledCommercialExchangesApi.getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday1ScheduledCommercialExchangesApi.getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday1ScheduledCommercialExchangesApi - factory interface
 * @export
 */
export const Intraday1ScheduledCommercialExchangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday1ScheduledCommercialExchangesApiFp(configuration)
    return {
        /**
         * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 1 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday1ScheduledCommercialExchangesApi - interface
 * @export
 * @interface Intraday1ScheduledCommercialExchangesApi
 */
export interface Intraday1ScheduledCommercialExchangesApiInterface {
    /**
     * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApiInterface
     */
    getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApiInterface
     */
    getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApiInterface
     */
    getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApiInterface
     */
    getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

}

/**
 * Intraday1ScheduledCommercialExchangesApi - object-oriented interface
 * @export
 * @class Intraday1ScheduledCommercialExchangesApi
 * @extends {BaseAPI}
 */
export class Intraday1ScheduledCommercialExchangesApi extends BaseAPI implements Intraday1ScheduledCommercialExchangesApiInterface {
    /**
     * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApi
     */
    public getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday1ScheduledCommercialExchangesApiFp(this.configuration).getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average intraday 1 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApi
     */
    public getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday1ScheduledCommercialExchangesApiFp(this.configuration).getAverageIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApi
     */
    public getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday1ScheduledCommercialExchangesApiFp(this.configuration).getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 1 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 1 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday1ScheduledCommercialExchangesApi
     */
    public getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday1ScheduledCommercialExchangesApiFp(this.configuration).getIda1ScheduledCommercialExchangesIda1ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday2NetPositionApi - axios parameter creator
 * @export
 */
export const Intraday2NetPositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 2 net positions for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageNetPositionsIda2NetPositionsAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageNetPositionsIda2NetPositionsAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageNetPositionsIda2NetPositionsAverageGet', 'endTime', endTime)
            const localVarPath = `/ida2/net_positions/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for intraday 2 net positions for a given time interval
         * @summary Get data availability percentage for intraday 2 net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/ida2/net_positions/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter IDA2 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter net positions for IDA2 auction between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2NetPositionsIda2NetPositionsFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda2NetPositionsIda2NetPositionsFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda2NetPositionsIda2NetPositionsFilterGet', 'endTime', endTime)
            const localVarPath = `/ida2/net_positions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 2 net positions for a specific zone
         * @summary Get intraday 2 net positions for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneIda2NetPositionsBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getNetPositionsByZoneIda2NetPositionsBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/ida2/net_positions/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday2NetPositionApi - functional programming interface
 * @export
 */
export const Intraday2NetPositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday2NetPositionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 2 net positions for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageNetPositionsIda2NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageNetPositionsIda2NetPositionsAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2NetPositionApi.getAverageNetPositionsIda2NetPositionsAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for intraday 2 net positions for a given time interval
         * @summary Get data availability percentage for intraday 2 net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2NetPositionApi.getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter IDA2 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter net positions for IDA2 auction between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2NetPositionsIda2NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2NetPositionsIda2NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2NetPositionApi.getIda2NetPositionsIda2NetPositionsFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 2 net positions for a specific zone
         * @summary Get intraday 2 net positions for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2NetPositionApi.getNetPositionsByZoneIda2NetPositionsBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday2NetPositionApi - factory interface
 * @export
 */
export const Intraday2NetPositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday2NetPositionApiFp(configuration)
    return {
        /**
         * Get average intraday 2 net positions for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageNetPositionsIda2NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getAverageNetPositionsIda2NetPositionsAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for intraday 2 net positions for a given time interval
         * @summary Get data availability percentage for intraday 2 net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter IDA2 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter net positions for IDA2 auction between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2NetPositionsIda2NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getIda2NetPositionsIda2NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 2 net positions for a specific zone
         * @summary Get intraday 2 net positions for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday2NetPositionApi - interface
 * @export
 * @interface Intraday2NetPositionApi
 */
export interface Intraday2NetPositionApiInterface {
    /**
     * Get average intraday 2 net positions for a given time interval
     * @summary Get average net positions for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApiInterface
     */
    getAverageNetPositionsIda2NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get data availability percentage for intraday 2 net positions for a given time interval
     * @summary Get data availability percentage for intraday 2 net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApiInterface
     */
    getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Filter IDA2 net positions for a given bidding zone and time interval and return net positions
     * @summary Filter net positions for IDA2 auction between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApiInterface
     */
    getIda2NetPositionsIda2NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get intraday 2 net positions for a specific zone
     * @summary Get intraday 2 net positions for a specific zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApiInterface
     */
    getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

}

/**
 * Intraday2NetPositionApi - object-oriented interface
 * @export
 * @class Intraday2NetPositionApi
 * @extends {BaseAPI}
 */
export class Intraday2NetPositionApi extends BaseAPI implements Intraday2NetPositionApiInterface {
    /**
     * Get average intraday 2 net positions for a given time interval
     * @summary Get average net positions for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApi
     */
    public getAverageNetPositionsIda2NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday2NetPositionApiFp(this.configuration).getAverageNetPositionsIda2NetPositionsAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for intraday 2 net positions for a given time interval
     * @summary Get data availability percentage for intraday 2 net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApi
     */
    public getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday2NetPositionApiFp(this.configuration).getIda2DataAvailabilityIda2NetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter IDA2 net positions for a given bidding zone and time interval and return net positions
     * @summary Filter net positions for IDA2 auction between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApi
     */
    public getIda2NetPositionsIda2NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday2NetPositionApiFp(this.configuration).getIda2NetPositionsIda2NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 2 net positions for a specific zone
     * @summary Get intraday 2 net positions for a specific zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2NetPositionApi
     */
    public getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday2NetPositionApiFp(this.configuration).getNetPositionsByZoneIda2NetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday2PriceSpreadApi - axios parameter creator
 * @export
 */
export const Intraday2PriceSpreadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 2 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 2 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet', 'interval', interval)
            const localVarPath = `/ida2/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average intraday 2 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 2 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1', 'interval', interval)
            const localVarPath = `/ida2/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 2 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 2 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda2PriceSpreadFilterGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda2PriceSpreadFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda2PriceSpreadFilterGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda2PriceSpreadFilterGet', 'interval', interval)
            const localVarPath = `/ida2/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 2 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 2 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2', 'interval', interval)
            const localVarPath = `/ida2/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday2PriceSpreadApi - functional programming interface
 * @export
 */
export const Intraday2PriceSpreadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday2PriceSpreadApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 2 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 2 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PriceSpreadApi.getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average intraday 2 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 2 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PriceSpreadApi.getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 2 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 2 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PriceSpreadApi.getDayAheadPriceSpreadsIda2PriceSpreadFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 2 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 2 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PriceSpreadApi.getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday2PriceSpreadApi - factory interface
 * @export
 */
export const Intraday2PriceSpreadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday2PriceSpreadApiFp(configuration)
    return {
        /**
         * Get average intraday 2 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 2 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average intraday 2 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 2 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 2 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 2 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 2 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 2 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday2PriceSpreadApi - interface
 * @export
 * @interface Intraday2PriceSpreadApi
 */
export interface Intraday2PriceSpreadApiInterface {
    /**
     * Get average intraday 2 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 2 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get average intraday 2 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 2 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get intraday 2 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 2 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get intraday 2 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 2 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

}

/**
 * Intraday2PriceSpreadApi - object-oriented interface
 * @export
 * @class Intraday2PriceSpreadApi
 * @extends {BaseAPI}
 */
export class Intraday2PriceSpreadApi extends BaseAPI implements Intraday2PriceSpreadApiInterface {
    /**
     * Get average intraday 2 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 2 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday2PriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average intraday 2 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 2 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday2PriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadIda2PriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 2 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 2 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApi
     */
    public getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday2PriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsIda2PriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 2 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 2 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PriceSpreadApi
     */
    public getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday2PriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsIda2PriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday2PricesApi - axios parameter creator
 * @export
 */
export const Intraday2PricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Query between two intervals returns the average prices of all biding zones
         * @summary Get average intraday 2 prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda2PricesIda2PricesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda2PricesIda2PricesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda2PricesIda2PricesAverageGet', 'endTime', endTime)
            const localVarPath = `/ida2/prices/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for intraday 2 prices for a given time interval
         * @summary Get data availability percentage for intraday 2 prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2DataAvailabilityIda2PricesDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda2DataAvailabilityIda2PricesDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda2DataAvailabilityIda2PricesDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getIda2DataAvailabilityIda2PricesDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/ida2/prices/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 2 prices for a specific zone      bidding_zone: Bidding zone      limit: Limit the number of results
         * @summary Get Ida2 Prices By Zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2PricesByZoneIda2PricesBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getIda2PricesByZoneIda2PricesBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/ida2/prices/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday 2 prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2PricesIda2PricesFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda2PricesIda2PricesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda2PricesIda2PricesFilterGet', 'endTime', endTime)
            const localVarPath = `/ida2/prices/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday2PricesApi - functional programming interface
 * @export
 */
export const Intraday2PricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday2PricesApiAxiosParamCreator(configuration)
    return {
        /**
         * Query between two intervals returns the average prices of all biding zones
         * @summary Get average intraday 2 prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda2PricesIda2PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda2PricesIda2PricesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PricesApi.getAverageIda2PricesIda2PricesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for intraday 2 prices for a given time interval
         * @summary Get data availability percentage for intraday 2 prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PricesApi.getIda2DataAvailabilityIda2PricesDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 2 prices for a specific zone      bidding_zone: Bidding zone      limit: Limit the number of results
         * @summary Get Ida2 Prices By Zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PricesApi.getIda2PricesByZoneIda2PricesBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday 2 prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2PricesIda2PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2PricesIda2PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2PricesApi.getIda2PricesIda2PricesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday2PricesApi - factory interface
 * @export
 */
export const Intraday2PricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday2PricesApiFp(configuration)
    return {
        /**
         * Query between two intervals returns the average prices of all biding zones
         * @summary Get average intraday 2 prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda2PricesIda2PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getAverageIda2PricesIda2PricesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for intraday 2 prices for a given time interval
         * @summary Get data availability percentage for intraday 2 prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 2 prices for a specific zone      bidding_zone: Bidding zone      limit: Limit the number of results
         * @summary Get Ida2 Prices By Zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday 2 prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2PricesIda2PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda2PricesIda2PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday2PricesApi - interface
 * @export
 * @interface Intraday2PricesApi
 */
export interface Intraday2PricesApiInterface {
    /**
     * Query between two intervals returns the average prices of all biding zones
     * @summary Get average intraday 2 prices for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApiInterface
     */
    getAverageIda2PricesIda2PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Get data availability percentage for intraday 2 prices for a given time interval
     * @summary Get data availability percentage for intraday 2 prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApiInterface
     */
    getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Get intraday 2 prices for a specific zone      bidding_zone: Bidding zone      limit: Limit the number of results
     * @summary Get Ida2 Prices By Zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApiInterface
     */
    getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter intraday 2 prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApiInterface
     */
    getIda2PricesIda2PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

}

/**
 * Intraday2PricesApi - object-oriented interface
 * @export
 * @class Intraday2PricesApi
 * @extends {BaseAPI}
 */
export class Intraday2PricesApi extends BaseAPI implements Intraday2PricesApiInterface {
    /**
     * Query between two intervals returns the average prices of all biding zones
     * @summary Get average intraday 2 prices for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApi
     */
    public getAverageIda2PricesIda2PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday2PricesApiFp(this.configuration).getAverageIda2PricesIda2PricesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for intraday 2 prices for a given time interval
     * @summary Get data availability percentage for intraday 2 prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApi
     */
    public getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday2PricesApiFp(this.configuration).getIda2DataAvailabilityIda2PricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 2 prices for a specific zone      bidding_zone: Bidding zone      limit: Limit the number of results
     * @summary Get Ida2 Prices By Zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApi
     */
    public getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday2PricesApiFp(this.configuration).getIda2PricesByZoneIda2PricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter intraday 2 prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2PricesApi
     */
    public getIda2PricesIda2PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday2PricesApiFp(this.configuration).getIda2PricesIda2PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday2ScheduledCommercialExchangesApi - axios parameter creator
 * @export
 */
export const Intraday2ScheduledCommercialExchangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet', 'endTime', endTime)
            const localVarPath = `/ida2/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1', 'endTime', endTime)
            const localVarPath = `/ida2/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet: async (startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet', 'endTime', endTime)
            const localVarPath = `/ida2/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2: async (startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2', 'endTime', endTime)
            const localVarPath = `/ida2/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday2ScheduledCommercialExchangesApi - functional programming interface
 * @export
 */
export const Intraday2ScheduledCommercialExchangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday2ScheduledCommercialExchangesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2ScheduledCommercialExchangesApi.getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2ScheduledCommercialExchangesApi.getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2ScheduledCommercialExchangesApi.getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday2ScheduledCommercialExchangesApi.getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday2ScheduledCommercialExchangesApi - factory interface
 * @export
 */
export const Intraday2ScheduledCommercialExchangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday2ScheduledCommercialExchangesApiFp(configuration)
    return {
        /**
         * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 2 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday2ScheduledCommercialExchangesApi - interface
 * @export
 * @interface Intraday2ScheduledCommercialExchangesApi
 */
export interface Intraday2ScheduledCommercialExchangesApiInterface {
    /**
     * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApiInterface
     */
    getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApiInterface
     */
    getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApiInterface
     */
    getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApiInterface
     */
    getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

}

/**
 * Intraday2ScheduledCommercialExchangesApi - object-oriented interface
 * @export
 * @class Intraday2ScheduledCommercialExchangesApi
 * @extends {BaseAPI}
 */
export class Intraday2ScheduledCommercialExchangesApi extends BaseAPI implements Intraday2ScheduledCommercialExchangesApiInterface {
    /**
     * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApi
     */
    public getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday2ScheduledCommercialExchangesApiFp(this.configuration).getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average intraday 2 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApi
     */
    public getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday2ScheduledCommercialExchangesApiFp(this.configuration).getAverageIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApi
     */
    public getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday2ScheduledCommercialExchangesApiFp(this.configuration).getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 2 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 2 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday2ScheduledCommercialExchangesApi
     */
    public getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday2ScheduledCommercialExchangesApiFp(this.configuration).getIda2ScheduledCommercialExchangesIda2ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday3NetPositionApi - axios parameter creator
 * @export
 */
export const Intraday3NetPositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average net positions for IDA3 auction in all bidding zones for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageNetPositionsIda3NetPositionsAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageNetPositionsIda3NetPositionsAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageNetPositionsIda3NetPositionsAverageGet', 'endTime', endTime)
            const localVarPath = `/ida3/net_positions/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for intraday net positions for a given time interval
         * @summary Get data availability percentage for intraday net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/ida3/net_positions/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter IDA3 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter net positions for IDA3 auction between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3NetPositionsIda3NetPositionsFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3NetPositionsIda3NetPositionsFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3NetPositionsIda3NetPositionsFilterGet', 'endTime', endTime)
            const localVarPath = `/ida3/net_positions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get net positions for a specific zone
         * @summary Get net positions for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneIda3NetPositionsBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getNetPositionsByZoneIda3NetPositionsBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/ida3/net_positions/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday3NetPositionApi - functional programming interface
 * @export
 */
export const Intraday3NetPositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday3NetPositionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average net positions for IDA3 auction in all bidding zones for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageNetPositionsIda3NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageNetPositionsIda3NetPositionsAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3NetPositionApi.getAverageNetPositionsIda3NetPositionsAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for intraday net positions for a given time interval
         * @summary Get data availability percentage for intraday net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3NetPositionApi.getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter IDA3 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter net positions for IDA3 auction between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3NetPositionsIda3NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3NetPositionsIda3NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3NetPositionApi.getIda3NetPositionsIda3NetPositionsFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get net positions for a specific zone
         * @summary Get net positions for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZoneNetPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3NetPositionApi.getNetPositionsByZoneIda3NetPositionsBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday3NetPositionApi - factory interface
 * @export
 */
export const Intraday3NetPositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday3NetPositionApiFp(configuration)
    return {
        /**
         * Get average net positions for IDA3 auction in all bidding zones for a given time interval
         * @summary Get average net positions for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageNetPositionsIda3NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getAverageNetPositionsIda3NetPositionsAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for intraday net positions for a given time interval
         * @summary Get data availability percentage for intraday net positions
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter IDA3 net positions for a given bidding zone and time interval and return net positions
         * @summary Filter net positions for IDA3 auction between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3NetPositionsIda3NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getIda3NetPositionsIda3NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get net positions for a specific zone
         * @summary Get net positions for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>> {
            return localVarFp.getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday3NetPositionApi - interface
 * @export
 * @interface Intraday3NetPositionApi
 */
export interface Intraday3NetPositionApiInterface {
    /**
     * Get average net positions for IDA3 auction in all bidding zones for a given time interval
     * @summary Get average net positions for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApiInterface
     */
    getAverageNetPositionsIda3NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get data availability percentage for intraday net positions for a given time interval
     * @summary Get data availability percentage for intraday net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApiInterface
     */
    getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Filter IDA3 net positions for a given bidding zone and time interval and return net positions
     * @summary Filter net positions for IDA3 auction between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApiInterface
     */
    getIda3NetPositionsIda3NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

    /**
     * Get net positions for a specific zone
     * @summary Get net positions for a specific zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApiInterface
     */
    getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZoneNetPositionResponse>>;

}

/**
 * Intraday3NetPositionApi - object-oriented interface
 * @export
 * @class Intraday3NetPositionApi
 * @extends {BaseAPI}
 */
export class Intraday3NetPositionApi extends BaseAPI implements Intraday3NetPositionApiInterface {
    /**
     * Get average net positions for IDA3 auction in all bidding zones for a given time interval
     * @summary Get average net positions for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApi
     */
    public getAverageNetPositionsIda3NetPositionsAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday3NetPositionApiFp(this.configuration).getAverageNetPositionsIda3NetPositionsAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for intraday net positions for a given time interval
     * @summary Get data availability percentage for intraday net positions
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApi
     */
    public getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday3NetPositionApiFp(this.configuration).getIda3DataAvailabilityIda3NetPositionsDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter IDA3 net positions for a given bidding zone and time interval and return net positions
     * @summary Filter net positions for IDA3 auction between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApi
     */
    public getIda3NetPositionsIda3NetPositionsFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday3NetPositionApiFp(this.configuration).getIda3NetPositionsIda3NetPositionsFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get net positions for a specific zone
     * @summary Get net positions for a specific zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3NetPositionApi
     */
    public getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday3NetPositionApiFp(this.configuration).getNetPositionsByZoneIda3NetPositionsBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday3PriceSpreadApi - axios parameter creator
 * @export
 */
export const Intraday3PriceSpreadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 3 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 3 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet', 'interval', interval)
            const localVarPath = `/ida3/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average intraday 3 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 3 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1', 'interval', interval)
            const localVarPath = `/ida3/price_spread/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 3 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 3 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda3PriceSpreadFilterGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda3PriceSpreadFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda3PriceSpreadFilterGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda3PriceSpreadFilterGet', 'interval', interval)
            const localVarPath = `/ida3/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get intraday 3 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 3 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2', 'interval', interval)
            const localVarPath = `/ida3/price_spread/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday3PriceSpreadApi - functional programming interface
 * @export
 */
export const Intraday3PriceSpreadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday3PriceSpreadApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 3 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 3 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PriceSpreadApi.getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average intraday 3 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 3 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PriceSpreadApi.getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 3 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 3 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PriceSpreadApi.getDayAheadPriceSpreadsIda3PriceSpreadFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get intraday 3 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 3 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BzbPriceSpreadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PriceSpreadApi.getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday3PriceSpreadApi - factory interface
 * @export
 */
export const Intraday3PriceSpreadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday3PriceSpreadApiFp(configuration)
    return {
        /**
         * Get average intraday 3 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 3 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average intraday 3 price spread for all bidding zone borders in a given time interval
         * @summary Get average intraday 3 price spread
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 3 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 3 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Get intraday 3 price spreads for all bidding zone borders in a given time interval
         * @summary Get intraday 3 price spreads
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>> {
            return localVarFp.getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday3PriceSpreadApi - interface
 * @export
 * @interface Intraday3PriceSpreadApi
 */
export interface Intraday3PriceSpreadApiInterface {
    /**
     * Get average intraday 3 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 3 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get average intraday 3 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 3 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApiInterface
     */
    getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get intraday 3 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 3 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

    /**
     * Get intraday 3 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 3 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApiInterface
     */
    getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BzbPriceSpreadResponse>>;

}

/**
 * Intraday3PriceSpreadApi - object-oriented interface
 * @export
 * @class Intraday3PriceSpreadApi
 * @extends {BaseAPI}
 */
export class Intraday3PriceSpreadApi extends BaseAPI implements Intraday3PriceSpreadApiInterface {
    /**
     * Get average intraday 3 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 3 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday3PriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average intraday 3 price spread for all bidding zone borders in a given time interval
     * @summary Get average intraday 3 price spread
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApi
     */
    public getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday3PriceSpreadApiFp(this.configuration).getAverageDayAheadPriceSpreadIda3PriceSpreadAverageGet_1(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 3 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 3 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApi
     */
    public getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday3PriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsIda3PriceSpreadFilterGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get intraday 3 price spreads for all bidding zone borders in a given time interval
     * @summary Get intraday 3 price spreads
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PriceSpreadApi
     */
    public getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday3PriceSpreadApiFp(this.configuration).getDayAheadPriceSpreadsIda3PriceSpreadFilterGet_2(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday3PricesApi - axios parameter creator
 * @export
 */
export const Intraday3PricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Query returns the average prices of all biding zones for a given time interval
         * @summary Get average intraday prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3AveragePricesIda3PricesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3AveragePricesIda3PricesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3AveragePricesIda3PricesAverageGet', 'endTime', endTime)
            const localVarPath = `/ida3/prices/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data availability percentage for intraday prices for a given time interval
         * @summary Get data availability percentage for intraday prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3DataAvailabilityIda3PricesDataAvailabilityGet: async (startTime: string, endTime: string, interval: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3DataAvailabilityIda3PricesDataAvailabilityGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3DataAvailabilityIda3PricesDataAvailabilityGet', 'endTime', endTime)
            // verify required parameter 'interval' is not null or undefined
            assertParamExists('getIda3DataAvailabilityIda3PricesDataAvailabilityGet', 'interval', interval)
            const localVarPath = `/ida3/prices/data_availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query returns the prices of a specific zone for the latest available time
         * @summary Get intraday prices for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3PricesByZoneIda3PricesBiddingZoneGet: async (biddingZone: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'biddingZone' is not null or undefined
            assertParamExists('getIda3PricesByZoneIda3PricesBiddingZoneGet', 'biddingZone', biddingZone)
            const localVarPath = `/ida3/prices/{bidding_zone}`
                .replace(`{${"bidding_zone"}}`, encodeURIComponent(String(biddingZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3PricesIda3PricesFilterGet: async (startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3PricesIda3PricesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3PricesIda3PricesFilterGet', 'endTime', endTime)
            const localVarPath = `/ida3/prices/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZone !== undefined) {
                localVarQueryParameter['bidding_zone'] = biddingZone;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday3PricesApi - functional programming interface
 * @export
 */
export const Intraday3PricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday3PricesApiAxiosParamCreator(configuration)
    return {
        /**
         * Query returns the average prices of all biding zones for a given time interval
         * @summary Get average intraday prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3AveragePricesIda3PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3AveragePricesIda3PricesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PricesApi.getIda3AveragePricesIda3PricesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get data availability percentage for intraday prices for a given time interval
         * @summary Get data availability percentage for intraday prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataAvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime, endTime, interval, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PricesApi.getIda3DataAvailabilityIda3PricesDataAvailabilityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query returns the prices of a specific zone for the latest available time
         * @summary Get intraday prices for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PricesApi.getIda3PricesByZoneIda3PricesBiddingZoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3PricesIda3PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BiddingZonePriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3PricesIda3PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3PricesApi.getIda3PricesIda3PricesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday3PricesApi - factory interface
 * @export
 */
export const Intraday3PricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday3PricesApiFp(configuration)
    return {
        /**
         * Query returns the average prices of all biding zones for a given time interval
         * @summary Get average intraday prices for a given time interval
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3AveragePricesIda3PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda3AveragePricesIda3PricesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data availability percentage for intraday prices for a given time interval
         * @summary Get data availability percentage for intraday prices
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} interval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse> {
            return localVarFp.getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(axios, basePath));
        },
        /**
         * Query returns the prices of a specific zone for the latest available time
         * @summary Get intraday prices for a specific zone
         * @param {string} biddingZone 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Query between two intervals returns the prices of all biding zones
         * @summary Filter intraday prices between two timestamps
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string | null} [biddingZone] 
         * @param {string} [interval] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3PricesIda3PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>> {
            return localVarFp.getIda3PricesIda3PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday3PricesApi - interface
 * @export
 * @interface Intraday3PricesApi
 */
export interface Intraday3PricesApiInterface {
    /**
     * Query returns the average prices of all biding zones for a given time interval
     * @summary Get average intraday prices for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApiInterface
     */
    getIda3AveragePricesIda3PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Get data availability percentage for intraday prices for a given time interval
     * @summary Get data availability percentage for intraday prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApiInterface
     */
    getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig): AxiosPromise<DataAvailabilityResponse>;

    /**
     * Query returns the prices of a specific zone for the latest available time
     * @summary Get intraday prices for a specific zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApiInterface
     */
    getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter intraday prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApiInterface
     */
    getIda3PricesIda3PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<BiddingZonePriceResponse>>;

}

/**
 * Intraday3PricesApi - object-oriented interface
 * @export
 * @class Intraday3PricesApi
 * @extends {BaseAPI}
 */
export class Intraday3PricesApi extends BaseAPI implements Intraday3PricesApiInterface {
    /**
     * Query returns the average prices of all biding zones for a given time interval
     * @summary Get average intraday prices for a given time interval
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApi
     */
    public getIda3AveragePricesIda3PricesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday3PricesApiFp(this.configuration).getIda3AveragePricesIda3PricesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data availability percentage for intraday prices for a given time interval
     * @summary Get data availability percentage for intraday prices
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} interval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApi
     */
    public getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime: string, endTime: string, interval: string, options?: RawAxiosRequestConfig) {
        return Intraday3PricesApiFp(this.configuration).getIda3DataAvailabilityIda3PricesDataAvailabilityGet(startTime, endTime, interval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query returns the prices of a specific zone for the latest available time
     * @summary Get intraday prices for a specific zone
     * @param {string} biddingZone 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApi
     */
    public getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday3PricesApiFp(this.configuration).getIda3PricesByZoneIda3PricesBiddingZoneGet(biddingZone, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query between two intervals returns the prices of all biding zones
     * @summary Filter intraday prices between two timestamps
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string | null} [biddingZone] 
     * @param {string} [interval] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3PricesApi
     */
    public getIda3PricesIda3PricesFilterGet(startTime: string, endTime: string, biddingZone?: string | null, interval?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday3PricesApiFp(this.configuration).getIda3PricesIda3PricesFilterGet(startTime, endTime, biddingZone, interval, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Intraday3ScheduledCommercialExchangesApi - axios parameter creator
 * @export
 */
export const Intraday3ScheduledCommercialExchangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet', 'endTime', endTime)
            const localVarPath = `/ida3/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1: async (startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1', 'endTime', endTime)
            const localVarPath = `/ida3/scheduled_commercial_exchanges/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet: async (startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet', 'endTime', endTime)
            const localVarPath = `/ida3/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2: async (startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2', 'endTime', endTime)
            const localVarPath = `/ida3/scheduled_commercial_exchanges/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (biddingZoneFrom !== undefined) {
                localVarQueryParameter['bidding_zone_from'] = biddingZoneFrom;
            }

            if (biddingZoneTo !== undefined) {
                localVarQueryParameter['bidding_zone_to'] = biddingZoneTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Intraday3ScheduledCommercialExchangesApi - functional programming interface
 * @export
 */
export const Intraday3ScheduledCommercialExchangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Intraday3ScheduledCommercialExchangesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3ScheduledCommercialExchangesApi.getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3ScheduledCommercialExchangesApi.getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3ScheduledCommercialExchangesApi.getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledCommercialExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['Intraday3ScheduledCommercialExchangesApi.getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * Intraday3ScheduledCommercialExchangesApi - factory interface
 * @export
 */
export const Intraday3ScheduledCommercialExchangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Intraday3ScheduledCommercialExchangesApiFp(configuration)
    return {
        /**
         * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
         * @summary Get average intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
         * @summary Filter intraday 3 scheduled commercial exchanges
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [biddingZoneFrom] 
         * @param {string} [biddingZoneTo] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>> {
            return localVarFp.getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Intraday3ScheduledCommercialExchangesApi - interface
 * @export
 * @interface Intraday3ScheduledCommercialExchangesApi
 */
export interface Intraday3ScheduledCommercialExchangesApiInterface {
    /**
     * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApiInterface
     */
    getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApiInterface
     */
    getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApiInterface
     */
    getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

    /**
     * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApiInterface
     */
    getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledCommercialExchangeResponse>>;

}

/**
 * Intraday3ScheduledCommercialExchangesApi - object-oriented interface
 * @export
 * @class Intraday3ScheduledCommercialExchangesApi
 * @extends {BaseAPI}
 */
export class Intraday3ScheduledCommercialExchangesApi extends BaseAPI implements Intraday3ScheduledCommercialExchangesApiInterface {
    /**
     * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApi
     */
    public getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday3ScheduledCommercialExchangesApiFp(this.configuration).getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get average intraday 3 scheduled commercial exchanges for all bidding zone boreders in a given time interval
     * @summary Get average intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApi
     */
    public getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime: string, endTime: string, options?: RawAxiosRequestConfig) {
        return Intraday3ScheduledCommercialExchangesApiFp(this.configuration).getAverageIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesAverageGet_1(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApi
     */
    public getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday3ScheduledCommercialExchangesApiFp(this.configuration).getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter intraday 3 scheduled commercial exchanges for a given from and to bidding zone, and time interval
     * @summary Filter intraday 3 scheduled commercial exchanges
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [biddingZoneFrom] 
     * @param {string} [biddingZoneTo] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Intraday3ScheduledCommercialExchangesApi
     */
    public getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime: string, endTime: string, biddingZoneFrom?: string, biddingZoneTo?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return Intraday3ScheduledCommercialExchangesApiFp(this.configuration).getIda3ScheduledCommercialExchangesIda3ScheduledCommercialExchangesFilterGet_2(startTime, endTime, biddingZoneFrom, biddingZoneTo, limit, options).then((request) => request(this.axios, this.basePath));
    }
}



