(window as any).global = window;

import ReactDOM from 'react-dom/client';

import '@eliagroup-innersource/nova-components-native/dist/nova-components.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppLogin from './app/AppLogin';
import LazyLoader from './app/LazyLoader';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <AppLogin>
        <LazyLoader />

        <ToastContainer
            position='bottom-left'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </AppLogin>
);
