import { bidingZoneMappingClient } from 'helpers/clients';
import { BiddingZoneBordersResponse } from 'helpers/clients/swagger/backend/api';
import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import Keycloak from 'keycloak-js';
import { UserData } from 'types/UserData';

export const keycloakAtom = atom<Keycloak | null>(null);
export const userDataAtom = atom((get) => get(keycloakAtom)?.tokenParsed as UserData);

export const showChatbotDialogAtom = atom<boolean>(false);
export const chatbotHistoryAtom = atom<any>([]);
export const chatboxExternalQuestionAtom = atom<any>(undefined);

export const borderMarkerHoverAtom = atom<BiddingZoneBordersResponse | undefined>(undefined);

export const zoneIdHoverAtom = atom<string | undefined>(undefined);

export const webGLSupportedAtom = atom<boolean>(true);

export const menuOpenAtom = atom<boolean>(false);

export const isHoveringExchangeAtom = atom<boolean>(false);
export const callerLocationAtom = atom<boolean>(false);
export const isLoadingMapAtom = atom<boolean>(false);
export const isEmbeddedAtom = atom<boolean>(false);
export const isMobileAtom = atom<boolean>(false);
export const mapViewportAtom = atom<any>({
  latitude: 50,
  longitude: 5,
  zoom: 5,
});

export const isLeftPanelExpandedAtom = atom<boolean>(true);

export const isMovingMapAtom = atom<boolean>(true);

export const availableZones = atomWithQuery<string[]>(() => ({
  queryKey: ['availableZones'],
  queryFn: async () => {
    try {
      const zones = await bidingZoneMappingClient.readNonVirtualZonesBzMappingActiveZonesGet()
      return zones.data
    } catch (error) {
      throw new Error('Failed to fetch active zones');
    }
  },
}));
export const availableZonesListAtom = atom((get) => get(availableZones));

export const requestsAtom = atom<{
  url: string;
  duration?: number;
  size?: number;
  error?: string;
  statusCode?: number;
  startDate?: Date;
  response?: string;
}[]>([]);