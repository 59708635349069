/* tslint:disable */
/* eslint-disable */
/**
 * EMC2 Chatbot Backend
 * Backend service for the EMC2 Chatbot project.
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * ChatBotQuestionsApi - axios parameter creator
 * @export
 */
export const ChatBotQuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Query Document
         * @param {string} question 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDocumentEmc2ChatbotQueryPost: async (question: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'question' is not null or undefined
            assertParamExists('queryDocumentEmc2ChatbotQueryPost', 'question', question)
            const localVarPath = `/emc2/chatbot/query/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (question !== undefined) {
                localVarQueryParameter['question'] = question;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatBotQuestionsApi - functional programming interface
 * @export
 */
export const ChatBotQuestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatBotQuestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Query Document
         * @param {string} question 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryDocumentEmc2ChatbotQueryPost(question: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryDocumentEmc2ChatbotQueryPost(question, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatBotQuestionsApi.queryDocumentEmc2ChatbotQueryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatBotQuestionsApi - factory interface
 * @export
 */
export const ChatBotQuestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatBotQuestionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Query Document
         * @param {string} question 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDocumentEmc2ChatbotQueryPost(question: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.queryDocumentEmc2ChatbotQueryPost(question, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatBotQuestionsApi - interface
 * @export
 * @interface ChatBotQuestionsApi
 */
export interface ChatBotQuestionsApiInterface {
    /**
     * 
     * @summary Query Document
     * @param {string} question 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotQuestionsApiInterface
     */
    queryDocumentEmc2ChatbotQueryPost(question: string, options?: RawAxiosRequestConfig): AxiosPromise<any>;

}

/**
 * ChatBotQuestionsApi - object-oriented interface
 * @export
 * @class ChatBotQuestionsApi
 * @extends {BaseAPI}
 */
export class ChatBotQuestionsApi extends BaseAPI implements ChatBotQuestionsApiInterface {
    /**
     * 
     * @summary Query Document
     * @param {string} question 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatBotQuestionsApi
     */
    public queryDocumentEmc2ChatbotQueryPost(question: string, options?: RawAxiosRequestConfig) {
        return ChatBotQuestionsApiFp(this.configuration).queryDocumentEmc2ChatbotQueryPost(question, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Admin endpoint to upload a document. Extracts text, generates embeddings, and stores both the document and embeddings for later use.
         * @summary Upload Document
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentTrainingFileUploadPost: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocumentTrainingFileUploadPost', 'file', file)
            const localVarPath = `/training/file/upload/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainingApiAxiosParamCreator(configuration)
    return {
        /**
         * Admin endpoint to upload a document. Extracts text, generates embeddings, and stores both the document and embeddings for later use.
         * @summary Upload Document
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocumentTrainingFileUploadPost(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocumentTrainingFileUploadPost(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TrainingApi.uploadDocumentTrainingFileUploadPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainingApiFp(configuration)
    return {
        /**
         * Admin endpoint to upload a document. Extracts text, generates embeddings, and stores both the document and embeddings for later use.
         * @summary Upload Document
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentTrainingFileUploadPost(file: File, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.uploadDocumentTrainingFileUploadPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainingApi - interface
 * @export
 * @interface TrainingApi
 */
export interface TrainingApiInterface {
    /**
     * Admin endpoint to upload a document. Extracts text, generates embeddings, and stores both the document and embeddings for later use.
     * @summary Upload Document
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApiInterface
     */
    uploadDocumentTrainingFileUploadPost(file: File, options?: RawAxiosRequestConfig): AxiosPromise<any>;

}

/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI implements TrainingApiInterface {
    /**
     * Admin endpoint to upload a document. Extracts text, generates embeddings, and stores both the document and embeddings for later use.
     * @summary Upload Document
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingApi
     */
    public uploadDocumentTrainingFileUploadPost(file: File, options?: RawAxiosRequestConfig) {
        return TrainingApiFp(this.configuration).uploadDocumentTrainingFileUploadPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}



